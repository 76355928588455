
export default {
  props: {
    lists: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    goTo(slug) {
      this.$router.push(`/${slug}`);
    },
    loadMore() {
      this.$emit("more");
    },
  },
};
